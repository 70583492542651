<template>
  <div>
    <b-card v-if="hasFeedbackType">
      <template #header>
        <card-header
          title="Feedback richiesti"
        />
      </template>

      <b-row>
        <feedback-section
          v-for="feedback in userFeedbackSections"
          :key="feedback.key"
          :feedback="feedback"
        />
      </b-row>
    </b-card>
    <b-card>
      <template #header>
        <card-header
          title="Andamento Feedback Ricevuti"
        />
      </template>

      <line-chart-card
        :current-filter="currentFilter"
        :search-data="searchData"
        :dataset="dataset"
        chart-id="history"
        week
        doubleweek
        month
        year
        singleChart
      />
    </b-card>
  </div>
</template>

<script>
import { formatDateOnly } from '@/utils/formatterHelper';
import { isNotEmpty } from '@/utils/validators';

const FeedbackSection = () => import('@/components/userSections/FeedbackSection.vue');
const LineChartCard = () => import('@/components/chart/LineChartCard.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'ScoreFeedback',
  components: {
    LineChartCard, FeedbackSection, CardHeader,
  },
  props: {
    serialCodeCreation: String,
  },
  data() {
    return {
      headingScoring: 'STATUS & SCORING',
      headingStats: 'STATISTICHE',
      currentFilter: 'week',
      loading: false,
      dataset: [],
    };
  },
  created() {
    if (!this.$store.getters['tableUtils/hasFeedbackType']) {
      this.$store.dispatch('tableUtils/loadFeedbackType', 'it');
    }
  },
  computed: {
    serialCodeCreationDay() {
      return formatDateOnly(this.serialCodeCreation);
    },
    userFeedbackSections() {
      const feedbackTypes = this.$store.getters['tableUtils/getFeedbackType'];
      return feedbackTypes.map(this.prepareFeedback);
    },
    hasScoreData() {
      return this.$store.getters['score/hasCurrentScore'];
    },
    hasFeedbackType() {
      return this.$store.getters['tableUtils/hasFeedbackType'];
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.searchData(this.currentFilter);
    },
    prepareFeedback(type) {
      const score = this.$store.getters['score/getCurrentScore'] || {};
      const hasScore = this.hasScoreData;
      // console.log('prepareFeedback', type, hasScore, score);
      if (hasScore) {
        // console.log('prepareFeedback hasScore', type.key);
        const feedback = score.sectionTotals[type.key];
        if (isNotEmpty(feedback)) {
          return {
            ...type,
            hasValues: true,
            feedbackValues: { ...feedback },
          };
        }
      }
      return {
        ...type,
        hasValues: false,
      };
    },
    searchData(filter) {
      if (!this.loading) {
        this.loading = true;
        this.currentFilter = filter;
        const entityType = this.isCompanyProfile ? 'company' : 'person';
        const entityId = this.isCompanyProfile ? this.$store.getters['subject/currentCompany'].companyId : null;
        this.$store.dispatch('score/fetchFeedbackHistoryStatistics', { entityType, entityId, interval: filter })
          .then((response) => {
            console.log('fetch response', response);
            this.dataset = response.map((item) => ({ x: new Date(item.date), y: item.totalFeedback }));
            this.loading = false;
          }, (error) => {
            console.log('Error fetch', error);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
